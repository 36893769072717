export const MAX_FILE_SIZE_BYTES = 400 * 1000000; // 400mb in bytes

export enum LinkType {
  MUX_VIDEO = "MUX_VIDEO",
  VIDEO = "VIDEO",
  HOSTED_VIDEO = "HOSTED_VIDEO",
  IMAGE = "IMAGE",
  GIF = "GIF",
  AUDIO = "AUDIO",
  LINK = "LINK",
  VCARD = "VCARD",
  PDF = "PDF",
  EMOJI = "EMOJI",
}

export const linkTypeCopy: { [key in LinkType]: string } = {
  [LinkType.MUX_VIDEO]: "Video",
  [LinkType.VIDEO]: "Video",
  [LinkType.HOSTED_VIDEO]: "Video",
  [LinkType.IMAGE]: "Image",
  [LinkType.GIF]: "Gif",
  [LinkType.AUDIO]: "Audio",
  [LinkType.LINK]: "Link",
  [LinkType.VCARD]: "Contact Card",
  [LinkType.PDF]: "PDF",
  [LinkType.EMOJI]: "Emoji",
};

export function getLinkType(link: string): LinkType {
  const extension = getExtension(link);
  if (!extension) {
    return LinkType.LINK;
  } else if (GIF_EXTENSIONS.indexOf(extension) !== -1) {
    return LinkType.GIF;
  } else if (IMAGE_EXTENSIONS.indexOf(extension) !== -1 || isUnsplash(link)) {
    return LinkType.IMAGE;
  } else if (AUDIO_EXTENSIONS.indexOf(extension) !== -1) {
    return LinkType.AUDIO;
  } else if (isMux(link)) {
    return LinkType.MUX_VIDEO;
  } else if (VIDEO_EXTENSIONS.indexOf(extension) !== -1) {
    return LinkType.VIDEO;
  } else if (VCARD_EXTENSIONS.indexOf(extension) !== -1) {
    return LinkType.VCARD;
  } else if (isYoutube(link) || isVimeo(link)) {
    return LinkType.HOSTED_VIDEO;
  } else if (PDF_EXTENSIONS.indexOf(extension) !== -1) {
    return LinkType.PDF;
  } else {
    return LinkType.LINK;
  }
}

function isYoutube(link: string): boolean {
  return link.toLowerCase().includes("youtube.com");
}

function isVimeo(link: string): boolean {
  return link.toLowerCase().includes("vimeo.com");
}

export function isMux(link: string): boolean {
  return link.toLowerCase().includes("stream.mux.com");
}

function isUnsplash(link: string): boolean {
  return link.toLowerCase().includes("images.unsplash.com");
}

export function isHls(link: string): boolean {
  const ext = getExtension(link);
  return ext ? HLS_EXTENSIONS.indexOf(ext) > -1 : false;
}

const HLS_EXTENSIONS = ["m3u8"];
const GIF_EXTENSIONS = ["gif"];
const IMAGE_EXTENSIONS = ["png", "jpg", "jpeg", "webp"];
const AUDIO_EXTENSIONS = ["mp3"];
const VCARD_EXTENSIONS = ["vcf"];
const PDF_EXTENSIONS = ["pdf"];
const VIDEO_EXTENSIONS = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m3u8",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];

export const ACCEPTED_FILE_TYPES = [
  ".gif",
  ".png",
  ".jpg",
  ".jpeg",
  ".mp3",
  ".m4a",
  ".wav",
  ".pdf",
  ".avi",
  ".flv",
  ".mkv",
  ".mov",
  ".mp4",
  ".webm",
  ".wmv",
  ".ppt",
  ".pptm",
  ".pptx",
  ".webp",
  ".doc",
  ".docx",
  ".csv",
  ".xls",
  ".xlsx",
];
export const ACCEPTED_FILE_TYPES_GROUPED = {
  "image/*": [".gif", ".png", ".jpg", ".jpeg", ".webp"],
  "audio/*": [".mp3", ".m4a", ".wav"],
  "application/pdf": [".pdf"],
  "video/*": [".avi", ".flv", ".mkv", ".mov", ".mp4", ".webm", ".wmv"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
    ".pptm",
  ],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "text/csv": [".csv"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};
export const ACCEPTED_IMAGE_TYPES = [".png", ".jpg", ".jpeg"];
export function getExtension(link: string): string | undefined {
  return link.split(".").pop()?.split("?")[0];
}

export function getMuxVideoId(link: string): string {
  const regExp = /^.*(stream.mux.com\/)([^#&?]*).m3u8/;
  const match = link.match(regExp);
  return match?.[2] || "";
}

export function humanFileSize(size: number): string {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(1) +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}
